<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Upload Logos</h5>
            </div>
            <div class="card">
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="DashboardLogoList" :lazy="true" :paginator="true" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                                class="p-datatable-users" data-key="tj1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <template v-slot:right>
                                        <Button label="Add New Logos" icon="pi pi-plus" @click="onAddImage" />
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>
                                <Column header="Sr." headerStyle="width: 4%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <Column header="User Type" headerStyle="width: 25%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <span v-if="data.tj2 == 1"> Admin</span>
                                            <span v-if="data.tj2 == 2"> Host</span>
                                            <span v-if="data.tj2 == 3"> Law Inforcement</span>
                                            <span v-if="data.tj2 == 4"> Super Admin</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Header Image" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div>
                                            <span v-if="data.tj4 != null && data.tj4 != ''">
                                                <img :src="data.tj4" width="50" style="vertical-align: middle" />
                                            </span>
                                            <span v-if="data.tj4 == null || data.tj4 == ''">-</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Footer Image" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div>
                                            <span v-if="data.tj6 != null && data.tj6 != ''">
                                                <img :src="data.tj6" width="50" style="vertical-align: middle" />
                                            </span>
                                            <span v-if="data.tj6 == null || data.tj6 == ''">-</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Sidebar Image" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div>
                                            <span v-if="data.tj8 != null && data.tj8 != ''">
                                                <img :src="data.tj8" width="50" style="vertical-align: middle" />
                                            </span>
                                            <span v-if="data.tj8 == null || data.tj8 == ''">-</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Favicon Image" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div>
                                            <span v-if="data.tj10 != null && data.tj10 != ''">
                                                <img :src="data.tj10" width="50" style="vertical-align: middle" />
                                            </span>
                                            <span v-if="data.tj10 == null || data.tj10 == ''">-</span>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditImage(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit Image Dialog starts here -->
    <Dialog v-model:visible="isDialogOpen" :style="{ width: '800px' }" :modal="true" class="p-fluid"
        :header="dialogHeader">
        <div style="position: relative">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="userTypeId">
                                    User Type
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="userTypeId" v-model="userType" :options="userTypeList" optionLabel="label"
                                    placeholder="Select ..." :filter="true" appendTo="body">
                                </Dropdown>
                                <small class="p-invalid p-error" v-if="v$.userType.$error">{{
                                    v$.userType.$errors[0].$message
                                }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="image">
                                    Header Image
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <FileUpload mode="basic" ref="headerimagefile" :maxFileSize="1000000" accept="image/*"
                                    chooseLabel="Upload Header Image" auto @select="handleHeaderImageUpload" />
                                <small class="p-invalid p-error" v-if="headerimageerror">{{ headerimageerror
                                    }}</small>
                                <small class="p-invalid p-error"
                                    v-if="v$.headerimagefile.$error && headerimageerror == ''">{{
                                        v$.headerimagefile.$errors[0].$message
                                    }}</small>
                                <div>
                                    <img v-if="headerImageUrl && headerImageUrl.url" class="p-pt-2" role="presentation"
                                        :alt="headerImageUrl.name" :src="headerImageUrl.url" width="120" height="120" />
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="image">
                                    Footer Image
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <FileUpload mode="basic" ref="footerimagefile" :maxFileSize="1000000" accept="image/*"
                                    chooseLabel="Upload Image" auto @select="handleFooterImageUpload" />
                                <small class="p-invalid p-error" v-if="footerimageerror">{{ footerimageerror
                                    }}</small>
                                <small class="p-invalid p-error"
                                    v-if="v$.footerimagefile.$error && footerimageerror == ''">{{
                                        v$.footerimagefile.$errors[0].$message
                                    }}</small>
                                <div>
                                    <img v-if="footerImageUrl && footerImageUrl.url" class="p-pt-2" role="presentation"
                                        :alt="footerImageUrl.name" :src="footerImageUrl.url" width="120" height="120" />
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="image">
                                    Sidebar Image
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <FileUpload mode="basic" ref="sidebarimagefile" :maxFileSize="1000000" accept="image/*"
                                    chooseLabel="Upload Image" auto @select="handleSidebarImageUpload" />
                                <small class="p-invalid p-error" v-if="sidebarimageerror">{{ sidebarimageerror
                                    }}</small>
                                <small class="p-invalid p-error"
                                    v-if="v$.sidebarimagefile.$error && sidebarimageerror == ''">{{
                                        v$.sidebarimagefile.$errors[0].$message
                                    }}</small>
                                <div>
                                    <img v-if="sidebarImageUrl && sidebarImageUrl.url" class="p-pt-2"
                                        role="presentation" :alt="sidebarImageUrl.name" :src="sidebarImageUrl.url"
                                        width="120" height="120" />
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-6">
                                <label for="image">
                                    Favicon Image
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <FileUpload mode="basic" ref="faviconimagefile" :maxFileSize="1000000" accept="image/*"
                                    chooseLabel="Upload Image" auto @select="handleFaviconImageUpload" />
                                <small class="p-invalid p-error" v-if="faviconimageerror">{{ faviconimageerror
                                    }}</small>
                                <small class="p-invalid p-error"
                                    v-if="v$.faviconimagefile.$error && faviconimageerror == ''">{{
                                        v$.faviconimagefile.$errors[0].$message
                                    }}</small>
                                <div>
                                    <img v-if="faviconImageUrl && faviconImageUrl.url" class="p-pt-2"
                                        role="presentation" :alt="faviconImageUrl.name" :src="faviconImageUrl.url"
                                        width="120" height="120" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
                class="p-button-text p-ml-auto" @click="addImage" style="width: 90px;" :disabled="showLoader"></Button>
        </template>
    </Dialog>
    <!-- Add/Edit Image Dialog ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
    data() {
        return {
            DashboardLogoList: [],
            v$: useValidate(),
            isDialogOpen: false,
            isSubmitted: false,
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            userType: 0,

            headerimagefile: null,
            headerImageUrl: null,
            headerimageerror: '',

            footerimagefile: null,
            footerImageUrl: null,
            footerimageerror: '',

            sidebarimagefile: null,
            sidebarImageUrl: null,
            sidebarimageerror: '',

            faviconimagefile: null,
            faviconImageUrl: null,
            faviconimageerror: '',

            dialogHeader: "",
            tj1: 0,
            userTypeList: [
                { label: 'Admin', value: 1 },
                { label: 'Host', value: 2 },
                { label: 'Law Inforcement', value: 3 },
                { label: 'Super Admin', value: 4 },
            ],
        };
    },

    validations() {
        return {
            headerimagefile: { required: helpers.withMessage('Please select image', required) },
            footerimagefile: { required: helpers.withMessage('Please select image', required) },
            sidebarimagefile: { required: helpers.withMessage('Please select image', required) },
            faviconimagefile: { required: helpers.withMessage('Please select image', required) },

            userType: { required: helpers.withMessage('Please select usertype', required) }
        };
    },

    ApiService: null,
    interval: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        this.getDashboardLogoUploadDetails({ page_no: this.page_no });
    },

    methods: {
        getDashboardLogoUploadDetails(ev) {
            this.loading = true;
            this.ApiService.getDashboardLogoUploadDetails(ev).then((data) => {
                if (data.status == 200) {
                    this.DashboardLogoList = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.DashboardLogoList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        onAddImage() {
            this.v$.$reset();
            this.xyz = "";
            this.dialogHeader = 'Add Image';
            this.isDialogOpen = true;
            this.headerimagefile = '';
            this.headerimagefile = '';
            this.footerimagefile = '';
            this.sidebarimagefile = '';
            this.faviconimagefile = '';
            this.tj1 = 0;
            this.headerImageUrl = null;
            this.headerImageUrl = null;
            this.footerImageUrl = null;
            this.sidebarImageUrl = null;
            this.faviconImageUrl = null;
            this.userType = 0;
        },

        onEditImage(data) {
            console.log(data);
            this.dialogHeader = "Edit Image";
            this.isDialogOpen = true;
            this.headerimagefile = data.tj4;
            this.footerimagefile = data.tj6;
            this.sidebarimagefile = data.tj8;
            this.faviconimagefile = data.tj10;

            let userType_id = this.userTypeList.filter(function (item) {
                return item.value == data.tj2;
            });
            if (userType_id.length > 0) {
                this.userType = userType_id[0];
            }

            this.tj1 = data.tj1;
            this.headerImageUrl = { name: data.tj3, url: data.tj4 };
            this.footerImageUrl = { name: data.tj5, url: data.tj6 };
            this.sidebarImageUrl = { name: data.tj7, url: data.tj8 };
            this.faviconImageUrl = { name: data.tj9, url: data.tj10 };
        },

        async addImage() {
            this.isSubmitted = true;
            this.v$.$validate();
            if (this.v$.error) return;
            let formData = new FormData();
            formData.append("tj1", this.tj1);
            formData.append("tj2", this.userType.value);
            formData.append("tj3", this.headerimagefile);
            formData.append("tj5", this.footerimagefile);
            formData.append("tj7", this.sidebarimagefile);
            formData.append("tj9", this.faviconimagefile);

            if (!this.v$.$error) {
                this.loading = this.showLoader = true;
                let result = await this.ApiService.createUpdateDashboardLogos(formData)
                this.loading = this.showLoader = this.isDialogOpen = false;
                if (result.success) {
                    this.getDashboardLogoUploadDetails({ page_no: this.page_no });
                    this.headerimagefile = null;
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: result.message, life: 3000 });
                }
            }
        },

        handleHeaderImageUpload() {
            var headerimagefile = this.$refs.headerimagefile.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!headerimagefile) {
                this.headerimageerror = 'Please select image';
            } else if (!headerimagefile.size > 2048) {
                this.headerimageerror = 'File size cannot exceed more than 2MB';
                this.headerImageUrl = null;
            } else if (!headerimagefile.name.match(allowedExtensions)) {
                this.headerimageerror = 'Invalid file type';
                this.headerImageUrl = null;
            } else {
                this.headerimagefile = this.$refs.headerimagefile.files[0];
                this.headerImageUrl = { name: this.headerimagefile.name, url: this.headerimagefile.objectURL };
                this.headerimageerror = '';
            }
        },

        handleFooterImageUpload() {
            var footerimagefile = this.$refs.footerimagefile.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!footerimagefile) {
                this.footerimageerror = 'Please select image';
            } else if (!footerimagefile.size > 2048) {
                this.footerimageerror = 'File size cannot exceed more than 2MB';
                this.footerImageUrl = null;
            } else if (!footerimagefile.name.match(allowedExtensions)) {
                this.footerimageerror = 'Invalid file type';
                this.footerImageUrl = null;
            } else {
                this.footerimagefile = this.$refs.footerimagefile.files[0];
                this.footerImageUrl = { name: this.footerimagefile.name, url: this.footerimagefile.objectURL };
                this.footerimageerror = '';
            }
        },
        handleSidebarImageUpload() {
            var sidebarimagefile = this.$refs.sidebarimagefile.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!sidebarimagefile) {
                this.sidebarimageerror = 'Please select image';
            } else if (!sidebarimagefile.size > 2048) {
                this.sidebarimageerror = 'File size cannot exceed more than 2MB';
                this.sidebarImageUrl = null;
            } else if (!sidebarimagefile.name.match(allowedExtensions)) {
                this.sidebarimageerror = 'Invalid file type';
                this.sidebarImageUrl = null;
            } else {
                this.sidebarimagefile = this.$refs.sidebarimagefile.files[0];
                this.sidebarImageUrl = { name: this.sidebarimagefile.name, url: this.sidebarimagefile.objectURL };
                this.sidebarimageerror = '';
            }
        },
        handleFaviconImageUpload() {
            var faviconimagefile = this.$refs.faviconimagefile.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!faviconimagefile) {
                this.faviconimageerror = 'Please select image';
            } else if (!faviconimagefile.size > 2048) {
                this.faviconimageerror = 'File size cannot exceed more than 2MB';
                this.faviconImageUrl = null;
            } else if (!faviconimagefile.name.match(allowedExtensions)) {
                this.faviconimageerror = 'Invalid file type';
                this.faviconImageUrl = null;
            } else {
                this.faviconimagefile = this.$refs.faviconimagefile.files[0];
                this.faviconImageUrl = { name: this.faviconimagefile.name, url: this.faviconimagefile.objectURL };
                this.faviconimageerror = '';
            }
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getDashboardLogoUploadDetails({ page_no: event.page });
        },
    }
};
</script>

<style scoped></style>
